<template>
  <Page class="page-users page-list-user">
    <template #title>Utenti</template>

    <template #default>
      <template v-if="users && users.length > 0">
        <AwlTable :heading="tableHeadings" :rows="usersCleaned" striped>
          <template #column-key-shop="{ row }">
            <router-link v-if="row.shop" :to="{ name: 'shops.show', params: { id: row.shop.id } }">{{ row.shop.name }}</router-link>
            <span v-else></span>
          </template>

          <template #column-key-status="{ row }">
            <template v-if="row.status === 'active'">Attivo</template>
            <template v-else-if="row.status === 'deleted'">Cancellato</template>
            <template v-else-if="row.status === 'pending'">Da attivare</template>
          </template>

          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'users.show', params: { id: row.id } }" />
          </template>
        </AwlTable>

        <div class="row justify-content-end align-items-center">
          <div class="col-auto text-right">
            <p>
              Risultati da mostrare
              <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
            </p>
          </div>
        </div>

        <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
      </template>
      <Alert v-else status="info">Nessuno shop presente</Alert>
    </template>
  </Page>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import pageableMixin from '@/libs/Pagination/mixins/pageable.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import rolesMixin from '@/mixins/roles.js';

import Page from '@/views/components/Private/Page.vue';
import AwlPagination from '@/libs/Pagination/components/AwlPagination.vue';
import PerPageSelector from '@/libs/Pagination/components/PerPageSelector.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listUsers()
    .then(res => next(vm => {
      vm.users = res?.data?.users || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [pageableMixin, confirmWithModalMixin, rolesMixin],
  components: {
    Page,
    AwlPagination,
    PerPageSelector,
    AwlTable,
    ActionShow,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  data: () => ({ users: [] }),
  computed: {
    tableHeadings () {
      return [
        { key: 'firstName', label: 'Nome' },
        { key: 'lastName', label: 'Cognome' },
        { key: 'email', label: 'Email' },
        { key: 'shop', label: 'Shop' },
        { key: 'statusName', label: 'Stato' },
      ];
    },
    usersCleaned () {
      return this.users.map(u => {
        if (u.status === 'pending') {
          u.statusName = 'Da attivare';
        } else if (u.status === 'deleted') {
          u.statusName = 'Bannato';
        } else if (u.status === 'active') {
          u.statusName = 'Attivo';
        }

        return u;
      });
    },
  },
  methods: {
    fetchResults (page = this.page) {
      return $api.listUsers(page, this.perPage)
        .then(res => {
          this.users = res?.data?.users || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
